import TextCell from "../components/TextCell";
import { Paper, TableBody, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import { useState} from "react";
import SwitchCell from "../components/SwitchCell";
import { ISliderInterface } from "../components/SliderCell";
import { ISelectValue } from "../components/SelectCell";
import { LivenessMode } from "../../../types/FaceVerification";
import ISOLivenessTable from "../components/ISOLivenessTable";
import { FVInitParams } from "neurotec-megamatcherid-management-client";
import { DefaultButton, SettingTable, TableCellButton, settingUseStyles } from "../Settings";
import SliderRow from "../components/SliderRow";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { useSnackbar } from "notistack";
import LivenessActionSequenceRow from "../components/LivenessActionSequenceRow";
import LivenessModeSelectRow from "../components/LivenessModeSelectRow";

interface ILiveness {
    options: FVInitParams,
    setLivenessMode: (liveness: LivenessMode) => void,
    findDefaultFVSliderInterface: (name: string) => ISliderInterface | undefined,
    findFVSliderInterface: (name: string) => (ISliderInterface | undefined),
    setNewOption: (op: ISliderInterface) => void,
    setOptions: React.Dispatch<React.SetStateAction<FVInitParams>>,
    handleQualityImageSetting: (w: number, h: number) => void,
    resetValueToDefault: (name: string) => void,
}

const Liveness: React.FC<ILiveness> = (props) => {
    
    const [selectLiveness, setSelectLiveness] = useState((props.options.livenessMode !== undefined && props.options.livenessMode > 0)? props.options.livenessMode.toString() : "1")
    const [active, setActive] = useState<boolean>((props.options?.livenessMode && props.options?.livenessMode > 0)? true : false);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const classes = settingUseStyles();
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const { enqueueSnackbar } = useSnackbar();

    const setLiveness = (liveness: boolean) => {
        setActive(liveness)
        if (liveness) {
            props.setLivenessMode(Number(selectLiveness))
        }
        else
            props.setLivenessMode(LivenessMode.NONE)
    }
    
    const getEnumValuesAndKey = (): ISelectValue[] => {
        return Object.keys(LivenessMode).map((e) => {
            if (Number(e) > 0) {
                return {key: LivenessMode[e as any], value: e}
            }
            return undefined
        }).filter((el) => el !== undefined) as ISelectValue[]
    }

    const handleResetClick = () => {
        enqueueSnackbar("Liveness settings reset", {variant: "info"})
        props.resetValueToDefault("LivenessThreshold")
        props.resetValueToDefault("PassiveLivenessSensitivityThreshold")
        props.resetValueToDefault("PassiveLivenessQualityThreshold")
        props.resetValueToDefault("LivenessBlinkTimeout")
        handleSetLivenessMode("1")
    }

    const handleSetLivenessMode = (mode: string) => {
        setSelectLiveness(mode)
        props.setLivenessMode(Number(mode))
    }

    const handleLivenessActionSequenceApply = (string: string) => {
        let par = props.options.advancedParameters?.find((e) => e.key === "LivenessCustomActionSequence")
        if (par) {
            par.value = string
            props.setOptions({...props.options, advancedParameters: props.options.advancedParameters})
        } else {
            if (props.options.advancedParameters)
                props.setOptions({...props.options, advancedParameters: [...props.options.advancedParameters, {key: "LivenessCustomActionSequence", value: string}]})
        }
    }

    return(
        <>
            <TableContainer component={Paper} className={classes.container}>
                <SettingTable>
                    <TableHead>
                        <TableRow>
                            <TextCell head title="Liveness" subtitle=""/>
                            <TableCellButton align="right" colSpan={isMobile? 1 : 2}>
                                <DefaultButton variant="text" color="primary" onClick={() => setOpenDialog(true)}>
                                    Reset to default
                                </DefaultButton>
                            </TableCellButton>
                            <SwitchCell state={active} setState={setLiveness}/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <LivenessModeSelectRow
                            isMobile={isMobile}
                            active={active}
                            getEnumValuesAndKey={getEnumValuesAndKey}
                            selectLiveness={selectLiveness}
                            handleSetLivenessMode={handleSetLivenessMode}
                        />
                        <LivenessActionSequenceRow
                            isMobile={isMobile}
                            active={active && props.options.livenessMode === LivenessMode.CUSTOM}
                            options={props.options}
                            apply={handleLivenessActionSequenceApply}
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Liveness threshold"
                            subtitle="Determines if liveness prediction is confident enough."
                            title="Threshold range"
                            option={props.findFVSliderInterface("LivenessThreshold")}
                            defaultOption={props.findDefaultFVSliderInterface("LivenessThreshold")}
                            setOption={props.setNewOption}
                            minValue={0}
                            maxValue={100}
                            type="raising"
                            active={active}
                            lockMaxValue
                            noWarningMsg="Everything will pass"
                            alwaysWarningMsg="Nothing will pass"
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Passive liveness sensitivity"
                            subtitle="Regulates the positioning restrictions."
                            title="Threshold range"
                            option={props.findFVSliderInterface("PassiveLivenessSensitivityThreshold")}
                            defaultOption={props.findDefaultFVSliderInterface("PassiveLivenessSensitivityThreshold")}
                            setOption={props.setNewOption}
                            minValue={0}
                            maxValue={100}
                            type="raising"
                            active={active}
                            lockMaxValue
                            noWarningMsg="Everything will pass"
                            alwaysWarningMsg="Nothing will pass"
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Passive liveness quality"
                            subtitle="Determines if image quality is suitable for liveness check."
                            title="Threshold range"
                            option={props.findFVSliderInterface("PassiveLivenessQualityThreshold")}
                            defaultOption={props.findDefaultFVSliderInterface("PassiveLivenessQualityThreshold")}
                            setOption={props.setNewOption}
                            minValue={0}
                            maxValue={100}
                            type="raising"
                            active={active}
                            lockMaxValue
                            noWarningMsg="Everything will pass"
                            alwaysWarningMsg="Nothing will pass"
                        />
                        <SliderRow
                            mobile={isMobile}
                            textTitle="Liveness blink timeout"
                            subtitle="Maximum time in ms when user has to blink when asked."
                            title="Threshold range"
                            option={props.findFVSliderInterface("LivenessBlinkTimeout")}
                            defaultOption={props.findDefaultFVSliderInterface("LivenessBlinkTimeout")}
                            setOption={props.setNewOption}
                            minValue={0}
                            maxValue={60000}
                            type="static"
                            active={active}
                            lockMaxValue
                            steps={1000}
                        />
                    </TableBody>
                </SettingTable>
            </TableContainer>
            <ISOLivenessTable
                options={props.options}
                setOptions={props.setOptions}
                setQualityImageSize={props.handleQualityImageSetting}
                active={active}
            />
            <ConfirmationDialog
                open={openDialog}
                setOpen={setOpenDialog}
                title="Reset liveness settings"
                text="Reset liveness settings to default values?"
                cancelText="Cancel"
                confirmText="Yes"
                confirm={handleResetClick}
                confirmColor="error"
            />
        </>
    );
}

export default Liveness;