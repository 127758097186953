import { CandidateData, FVInitParams, JanusApiConnectToJanusRequest, JanusApiTrickleJanusRequest, SessionData } from "neurotec-megamatcherid-management-client";
import { JanusAPI } from "../config/management-api";
import { IMessage } from "../store/actions";
import { LivenessMode, Operations, Status, StatusMessages } from "../types/FaceVerification";
import { drawIcaoArrows, drawPolygon, getVideoMargins, livenessActionUpdateBottom, livenessActionUpdateFace, LIVENESS_TEXT_LOCATION, updateIcaoWarnings, drawOval } from "./streamHelper";
import { THEME } from "../config";
import { ModalityType } from "../containers/Enroll/Enroll";

export const setupWebRTC = async (session: SessionData, mediatream: MediaStream) => {
    let rtcConnection = new window.RTCPeerConnection({
        iceServers: [{
            urls: 'stun:stun.l.google.com:19302'
        }]
    });

    rtcConnection.onicecandidate = (event) => {
        if (session && event.candidate != null) {
            const data: JanusApiTrickleJanusRequest = {
                trickleData: {
                    session,
                    data: event.candidate as CandidateData
                }
            };
            JanusAPI.trickleJanus(data).catch(err => {
                console.error(err);
            })
        }
    };

    mediatream.getTracks().forEach(track =>
        rtcConnection.addTrack(track, mediatream));

    return rtcConnection;
}

export const setupDataChannel = async (
    rtcConnection: RTCPeerConnection,
    session: SessionData,
    videoElement: HTMLVideoElement | undefined,
    audioElement: HTMLAudioElement | undefined,
    canvas: HTMLCanvasElement,
    div: HTMLDivElement,
    sendMessage: (mesage: IMessage) => void,
    onClose: (status?: Status) => void,
    onSuccess: (data: any) => void,
    livenessMode: Number | undefined
) => {

    let isClose = false

    let dataChannel = rtcConnection.createDataChannel('mmidJanus', {
        maxPacketLifeTime: 3000,
    });

    dataChannel.onerror = (error) => {
        console.error('Data Channel Error:', error);
    };
    dataChannel.onclose = () => {
        isClose = true
        console.info('The Data Channel is Closed');       
    };

    dataChannel.onmessage = (event) => {
        if (isClose) return
        try {
            let data: any = JSON.parse(event.data);
            console.debug("Received data:", data);
            sendMessage({ message: "", type: "success" })
            if (data.operationFinished) {
                //TODO: update liveness status (data)
                if (data.status as number === Status.SUCCESS) {

                    if (videoElement) {
                        videoElement.style.filter = "brightness(0.5)"
                    }


                    sendMessage({ message: StatusMessages[Status.SUCCESS], type: "success" })
                    onSuccess(data);
                } else {
                    if (data.errorMessage) {
                        console.error(data.errorMessage);
                        sendMessage({ message: data.errorMessage, type: "error" })
                    } else {
                        sendMessage({ message: StatusMessages[data.status as Status], type: "failure" })
                    }
                }
                onClose(data.status);
                return;
            }
            switch (data.status) {
                case Status.FACE_NOT_FOUND:
                    sendMessage({ message: "Searching for a face", type: "info" });
                    break;
                case Status.ADJUSTING_QUALITY:
                    sendMessage({ message: "Adjusting quality", type: "info" });
                    break;
                default:
                    break;
            }

            if (data.status !== Status.FACE_NOT_FOUND && data.boundingRect && videoElement) {
                var widthMargin;
                var heightMargin;
                [widthMargin, heightMargin] = getVideoMargins(videoElement);
                var rect = data.boundingRect;
                var x = (rect.x * (videoElement.offsetWidth - widthMargin)) / rect.fullImageWidth + (widthMargin - videoElement.offsetWidth + div.offsetWidth) / 2
                var y = (rect.y * (videoElement.offsetHeight - heightMargin)) / rect.fullImageHeight + (heightMargin - videoElement.offsetHeight + div.offsetHeight) / 2

                var width = rect.width / rect.fullImageWidth * (videoElement.offsetWidth - widthMargin);
                var height = rect.height / rect.fullImageHeight * (videoElement.offsetHeight - heightMargin);
                var yaw = data.yaw;
                var roll = data.roll;

                canvas.width = div.offsetWidth
                canvas.height = div.offsetHeight

                let ctx = canvas.getContext("2d");
                if (ctx) {
                    livenessActionUpdateBottom(data, canvas, ctx); // bottom active liveness rectangle
                    if (livenessMode === LivenessMode.PASSIVE || livenessMode === LivenessMode.PASSIVE_WITH_BLINK) { //face oval
                        drawOval(ctx, data.livenessAction, videoElement.videoWidth, videoElement.videoHeight,
                                canvas.width, canvas.height, 0.58, 6,
                                THEME.palette.success.dark, "rgba(128, 128, 128, 1)" ,"rgba(229, 229, 229, 0.80)") 
                    }
                    
                    ctx.translate(x + width / 2, y + height / 2);
                    ctx.rotate(roll * Math.PI / 180);
                    if (livenessMode !== LivenessMode.PASSIVE && livenessMode !== LivenessMode.PASSIVE_WITH_BLINK) {
                        drawPolygon(ctx, -width / 2, -height / 2, width, height, yaw, THEME.palette.success.dark, height / 100); // face rectangle
                    }

                    if (width > 0) {
                        drawIcaoArrows(data.icaoWarnings, ctx, yaw, width, height);
                        updateIcaoWarnings(data.icaoWarnings, sendMessage);
                    }

                    let livenessText = livenessActionUpdateFace(data, ctx, width, height * LIVENESS_TEXT_LOCATION); // text below face rectangle
                    if (livenessText !== "")
                        sendMessage({ message: livenessText, type: "warning" });
                }
            }
        } catch (err) {
            console.error(err);
        }
    }
}

export const connectToJanus = async (
    rtcConnection: RTCPeerConnection,
    session: SessionData,
    mmidInitParameters: FVInitParams,
    modality: ModalityType) => {

    let modalityInt = 0;
    if (modality === ModalityType.FACE_MODALITY) {
        modalityInt = 0
    } else {
        modalityInt = 1
    }
    
    let rtcOffer = await rtcConnection.createOffer();
    const data: JanusApiConnectToJanusRequest = {
        connectData: {
            jsep: rtcOffer,
            params: {...mmidInitParameters, modality: modalityInt},
            session,
        }
    };
    try {
        rtcConnection.setLocalDescription(rtcOffer);
        let janusResponse = (await JanusAPI.connectToJanus(data)).data;
        let pluginData: any = janusResponse.plugindata;
        if (janusResponse === undefined || pluginData.data.error) {
            return;
        }
        if (janusResponse.jsep === undefined) {
            throw new Error("Janus connection error")
        }
        rtcConnection.setRemoteDescription(new window.RTCSessionDescription(janusResponse.jsep as RTCSessionDescriptionInit));
        return janusResponse;
    } catch (err) {
        throw err;
    }
}

export const startSession = async (
    operationType: Operations,
    initParams: FVInitParams) => {
    if (operationType === Operations.CHECKS && initParams.livenessMode === LivenessMode.NONE && !initParams.checkIcaoCompliance) {
        throw new Error("Liveness mode and/or ICAO check has to be set");
    }
    return await JanusAPI.createAndAttach();
}

export const stopSession = async (
    session: SessionData | null,
    rtcConnection: RTCPeerConnection | null) => {
    if (rtcConnection) {
        rtcConnection.close();
    }
    if (session) {
        await JanusAPI.stopJanusConnection({ sessionData: session });
    }
}
