
import { IMessage } from "../store/actions";
import { IcaoWarnings, LivenessAction } from "../types/FaceVerification";

import ARROW_SRC from "../assets/images/arrow.svg";
import ROLL_SRC from "../assets/images/roll.svg";
import YAW_SRC from "../assets/images/yaw.svg";

export const LIVENESS_TEXT_LOCATION: number = 0.59;
const LIVENESS_ACTION_HEIGHT_DENOM = 20;

const yawArrow = new Image();
const moveArrow = new Image();
const rollArrow = new Image();

yawArrow.src = YAW_SRC;
moveArrow.src = ARROW_SRC;
rollArrow.src = ROLL_SRC;

export const drawIcaoArrows = (warnings: IcaoWarnings[], ctx: CanvasRenderingContext2D, yaw: number, width: number, height: number) => {
    var padding = 5;
    var sizeMultiplier = width / 150;

    if (warnings.includes(IcaoWarnings.ROLL_RIGHT)) {
        ctx.save();
        ctx.translate(-width / 2, -height / 2);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(-rollArrow.width / 2, -rollArrow.height / 2);
        ctx.drawImage(rollArrow, 0, 0);
        ctx.restore();
    }
    if (warnings.includes(IcaoWarnings.ROLL_LEFT)) {
        ctx.save();
        ctx.translate(width / 2, -height / 2);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(rollArrow.width / 2, -rollArrow.height / 2);
        ctx.scale(-1, 1);
        ctx.drawImage(rollArrow, 0, 0);
        ctx.restore();
    }

    if (warnings.includes(IcaoWarnings.TOO_SOUTH)) {
        ctx.save();
        ctx.translate(0, -height / 2 - padding);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(-moveArrow.height / 2, 0);
        ctx.rotate(-90 * Math.PI / 180);
        ctx.drawImage(moveArrow, 0, 0);
        ctx.restore();
    } else if (warnings.includes(IcaoWarnings.PITCH_DOWN)) {
        ctx.save();
        ctx.translate(0, -height / 2 + padding);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(-yawArrow.height / 2, yawArrow.height / 2);
        ctx.rotate(-90 * Math.PI / 180);
        ctx.drawImage(yawArrow, 0, 0);
        ctx.restore();
    }

    if (warnings.includes(IcaoWarnings.TOO_NORTH)) {
        ctx.save();
        ctx.translate(0, height / 2 + padding);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(-moveArrow.height / 2, 0);
        ctx.scale(-1, 1);
        ctx.rotate(90 * Math.PI / 180);
        ctx.drawImage(moveArrow, 0, 0);
        ctx.restore();
    } else if (warnings.includes(IcaoWarnings.PITCH_UP)) {
        ctx.save();
        ctx.translate(0, height / 2 - padding);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(-yawArrow.height / 2, -yawArrow.height / 2);
        ctx.scale(-1, 1);
        ctx.rotate(90 * Math.PI / 180);
        ctx.drawImage(yawArrow, 0, 0);
        ctx.restore();
    }

    if (warnings.includes(IcaoWarnings.TOO_WEST)) {
        var yawEastOffset = yaw < 0 ? (width / 5 * yaw) / 45 : 0;
        ctx.save();
        ctx.translate(width / 2 + padding - yawEastOffset, 0);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(0, -moveArrow.height / 2);
        ctx.drawImage(moveArrow, 0, 0);
        ctx.restore();
    } else if (warnings.includes(IcaoWarnings.YAW_RIGHT)) {
        var yawLeftOffset = yaw < 0 ? (width / 5 * yaw) / 45 : 0;
        ctx.save();
        ctx.translate(-width / 2 - padding - yawLeftOffset + padding, 0);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(-yawArrow.width / 2, -yawArrow.height / 2);
        ctx.drawImage(yawArrow, 0, 0);
        ctx.restore();
    }

    if (warnings.includes(IcaoWarnings.TOO_EAST)) {
        var yawWestOffset = yaw > 0 ? (width / 5 * yaw) / 45 : 0;
        ctx.save();
        ctx.translate(-width / 2 - padding - yawWestOffset, 0);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(0, -moveArrow.height / 2);
        ctx.scale(-1, 1);
        ctx.drawImage(moveArrow, 0, 0);
        ctx.restore();
    } else if (warnings.includes(IcaoWarnings.YAW_LEFT)) {
        var yawRightOffset = yaw > 0 ? (width / 5 * yaw) / 45 : 0;
        ctx.save();
        ctx.translate(width / 2 + padding - yawRightOffset - padding, 0);
        ctx.scale(sizeMultiplier, sizeMultiplier);
        ctx.translate(yawArrow.width / 2, -yawArrow.height / 2);
        ctx.scale(-1, 1);
        ctx.drawImage(yawArrow, 0, 0);
        ctx.restore();
    }
}

export const updateIcaoWarnings = (warnings: IcaoWarnings[], sendMessage: (message: IMessage) => void) => {
    if (warnings.includes(IcaoWarnings.TOO_NEAR)) {
        sendMessage({ message: "Too near", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.TOO_FAR)) {
        sendMessage({ message: "Too far", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.GLASSES)) {
        sendMessage({ message: "Glasses", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.HAT)) {
        sendMessage({ message: "Hat", type: "warning" });
    }  else if (warnings.includes(IcaoWarnings.HEAD_MOVEMENT)) {
        sendMessage({ message: "Head movement", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.SHARPNESS)) {
        sendMessage({ message: "Sharpness", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.BACKGROUND_UNIFORMITY)) {
        sendMessage({ message: "Background uniformity", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.GRAYSCALE_DENSITY)) {
        sendMessage({ message: "Grayscale density", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.SATURATION)) {
        sendMessage({ message: "Saturation", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.EXPRESSION)) {
        sendMessage({ message: "Expression", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.DARK_GLASSES)) {
        sendMessage({ message: "Dark glasses", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.EYES_CLOSED)) {
        sendMessage({ message: "Eyes closed", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.MOUTH_OPEN)) {
        sendMessage({ message: "Mouth open", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.LOOKING_AWAY)) {
        sendMessage({ message: "Looking away", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.RED_EYE)) {
        sendMessage({ message: "Red eye", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.FACE_DARKNESS)) {
        sendMessage({ message: "Face darkness", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.UNNATURAL_SKINTONE)) {
        sendMessage({ message: "Unnatural skin tone", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.WASHED_OUT)) {
        sendMessage({ message: "Colors washed out", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.PIXELATION)) {
        sendMessage({ message: "Pixelation", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.SKIN_REFLECTION)) {
        sendMessage({ message: "Skin reflection", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.GLASSES_REFLECTION)) {
        sendMessage({ message: "Glasses reflection", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.HEAVY_FRAME)) {
        sendMessage({ message: "Heavy frame", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.OCCLUSION_WARNING)) {
        sendMessage({ message: "Occlusion", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.RESOLUTION_WARNING)) {
        sendMessage({ message: "Resolution", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.MOTION_BLUR_WARNING)) {
        sendMessage({ message: "Motion blur", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.COMPRESSION_ARTIFACTS_WARNING)) {
        sendMessage({ message: "Compression artifacts", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.OVEREXPOSURE)) {
        sendMessage({ message: "Overexposure", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.UNDEREXPOSURE)) {
        sendMessage({ message: "Underexposure", type: "warning" });
    } else if (warnings.includes(IcaoWarnings.IMAGE_RESOLUTION)) {
        sendMessage({ message: "Image resolution", type: "warning" });
    }
}


export const drawPolygon = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    yaw: number,
    strokeStyle: string,
    strokeWidth: number) => {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = strokeWidth;

    ctx.moveTo(x, y);
    ctx.lineTo(x + width, y);
    if (yaw < 0) {
        ctx.lineTo(x + width - (width / 5 * yaw) / 45, y + height / 2);
    }
    ctx.lineTo(x + width, y + height);
    ctx.lineTo(x, y + height);
    if (yaw > 0) {
        ctx.lineTo(x - (width / 5 * yaw) / 45, y + height / 2);
    }
    ctx.lineTo(x, y);

    ctx.stroke();
    ctx.closePath();
    ctx.restore();
}

export const drawCircle = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    radius: number,
    stroke: string,
    strokeWidth: number) => {
    ctx.save();
    ctx.beginPath();
    ctx.strokeStyle = 'yellow';
    ctx.arc(x, y, radius, 0, 2 * Math.PI, false);
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
}

export const drawArrow = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    isLeft: boolean) => {
    var multiplier = isLeft ? 1 : -1;
    var translatedX = (multiplier * width / 4);
    var translatedY = (multiplier * height / 2);
    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = 'yellow';
    ctx.lineWidth = 1;
    ctx.moveTo(x, y);
    ctx.lineTo(x - translatedX, y - translatedY);
    ctx.lineTo(x + 2 * translatedX, y);
    ctx.lineTo(x - translatedX, y + translatedY);
    ctx.lineTo(x, y);
    ctx.fill();
    ctx.closePath();
    ctx.restore();
}

export const drawBlink = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number) => {
    ctx.save();
    ctx.strokeStyle = 'yellow';
    ctx.lineWidth = height / 6;
    ctx.beginPath();
    ctx.bezierCurveTo(x - width / 2.5, y - height / 5, x, y + height / 1.8, x + width / 2.5, y - height / 5);
    ctx.moveTo(x - width / 3.4, y - height / 27);
    ctx.lineTo(x - width / 2.5, y + height / 8);
    ctx.moveTo(x - width / 6, y + height / 13);
    ctx.lineTo(x - width / 4, y + height / 3.5);
    ctx.moveTo(x, y + height / 7);
    ctx.lineTo(x, y + height / 2.7);
    ctx.moveTo(x + width / 6, y + height / 13);
    ctx.lineTo(x + width / 4, y + height / 3.5);
    ctx.moveTo(x + width / 3.4, y - height / 27);
    ctx.lineTo(x + width / 2.5, y + height / 8);
    ctx.stroke();
    ctx.closePath();
    ctx.restore();
}


export const getVideoMargins = (videoElement: HTMLVideoElement) => {
    var videoRatio = videoElement.videoWidth / videoElement.videoHeight;
    var width = videoElement.offsetWidth;
    var height = videoElement.offsetHeight;
    var elementRatio = width / height;
    if (elementRatio > videoRatio) width = height * videoRatio;
    else height = width / videoRatio;
    var widthMargin = videoElement.offsetWidth - width;
    var heightMargin = videoElement.offsetHeight - height;
    return [widthMargin, heightMargin];
}

export const livenessActionUpdateFace = (data: any, ctx: CanvasRenderingContext2D, realRectangleWidth: number, textLocationY: number): string => {
    var action = data.livenessAction;
    var text = '';

    if (action.includes(LivenessAction.BLINK)) text = 'Please blink';
    else if (action.includes(LivenessAction.ROTATE_YAW)) text = 'Turn face on target';
    else if (action.includes(LivenessAction.KEEP_ROTATING_YAW)) text = 'Keep rotating';
    else if (action.includes(LivenessAction.TURN_TO_CENTER)) text = 'Turn face to center';
    else if (action.includes(LivenessAction.TURN_LEFT)) text = 'Turn face left';
    else if (action.includes(LivenessAction.TURN_RIGHT)) text = 'Turn face right';
    else if (action.includes(LivenessAction.TURN_UP)) text = 'Turn face up';
    else if (action.includes(LivenessAction.TURN_DOWN)) text = 'Turn face down';
    else if (action.includes(LivenessAction.MOVE_CLOSER)) text = 'Move closer';
    else if (action.includes(LivenessAction.MOVE_BACK)) text = 'Move back';
    else if (action.includes(LivenessAction.KEEP_STILL)) text = 'Keep still';

    return text
}


export const clearCanvas = (canvas: HTMLCanvasElement | null) => {
    if (canvas) {
        let ctx = canvas.getContext("2d");
        if (ctx) {
            ctx.resetTransform();
            ctx.clearRect(0, 0, canvas.width, canvas.height)
        }
    }
}

export const livenessActionUpdateBottom = (data: any, canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {

    var action = data.livenessAction;

    if (action.includes(LivenessAction.ROTATE_YAW)) {
        var yaw = data.yaw;
        var targetYaw = data.livenessTargetYaw;
        var imageWidth = canvas.width;
        var height = canvas.height / LIVENESS_ACTION_HEIGHT_DENOM;
        var width = height * 8;
        var y = canvas.height - height * 4;
        // move action bar up if needed (e.g. in iOS browsers)
        // y -= canvas.height - window.innerHeight;

        drawPolygon(ctx, (imageWidth - width) / 2, y, width, height, 0, 'yellow', height / 20); // action area

        var centerX = imageWidth / 2;
        var maxYaw = 46;
        var targetX = centerX + -targetYaw / maxYaw * width;
        var trueX = centerX + -yaw / maxYaw * width;
        if (action.includes(LivenessAction.ROTATE_YAW) && !action.includes(LivenessAction.BLINK)) {
            drawCircle(ctx, targetX, y + height / 2, height / 2 * 0.65, 'yellow', height / 10);
            drawCircle(ctx, targetX, y + height / 2, height / 2 * 0.25, 'yellow', height / 10);
            drawArrow(ctx, trueX, y + height / 2, height, height * 0.8, yaw > targetYaw);
        } else if (action.includes(LivenessAction.BLINK)) {
            drawBlink(ctx, trueX, y + height / 2, height * 1.25, height * 0.8);
        }
    }

}

export const drawOval = (
        ctx: CanvasRenderingContext2D,
        livenessAction: LivenessAction[],
        videoX: number,
        videoY: number,
        width: number,
        height: number,
        sizeRatio: number,
        ovalWidth: number,
        ovalOnStyle: string,
        ovalOffStyle: string,
        BackgroundStyle: string) => {
    let ratioVideo = videoX / videoY
    let ratioCanvas = width / height
    let ratio 
    let isWidth
    if (false){
        ratio = ratioVideo
        isWidth = false
    }
    else {
        ratio = ratioCanvas
        isWidth = true
    }
    let sizeX = (width / 2) * sizeRatio / ratio
    if (isWidth) {
        let diff = (sizeX + ((sizeX / 100) * 2)) - ((height * ratioVideo) / 2)
        if (diff > 0) {
            sizeX = sizeX - diff - ((height * ratioVideo) * 0.15)
        }
    }
    else {
        let diff = (sizeX + ((sizeX / 100) * 2)) - ((width) / 2)
        if (diff > 0) {
            sizeX = sizeX - diff - (width * 0.15)
        }
    }
    let sizeYRatio = 1.35
    let sizeY = sizeX * sizeYRatio
    let centX = width / 2
    let centY = height / 2

    ctx.beginPath();
    ctx.rect(0, 0, width, height)
    ctx.fillStyle = BackgroundStyle
    ctx.fill();

    ctx.beginPath()
    ctx.globalCompositeOperation = "destination-out"
    ctx.ellipse(centX, centY, sizeX, sizeY, 0, 0, 2 * Math.PI);
    ctx.fillStyle = "rgba(0, 0, 0, 1)";
    ctx.fill()

    ctx.beginPath();
    ctx.globalCompositeOperation = "source-over"
    ctx.lineWidth = ovalWidth * (sizeX / 100);
    ctx.ellipse(centX, centY, sizeX, sizeY, 0, 0, 2 * Math.PI);
    if (livenessAction.includes(LivenessAction.BLINK) || livenessAction.includes(LivenessAction.KEEP_STILL)) {
        ctx.strokeStyle = ovalOnStyle
    }
    else {
        ctx.strokeStyle = ovalOffStyle
    }
    ctx.stroke()
}
