import React from "react";
import { SettingType } from "../../../containers/Settings/Settings";

export enum ActionType {
    ClickMenu,
    ToggleDrawer,
    ToggleMobileMenu,
    ClosePage,
    SetSettingType,
    SetOptionsActive,
    SetDeveloperOptions,
    SetLoading,
}

export interface ClickMenu {
    type: ActionType.ClickMenu,
    page: React.FC<any>
}

export interface ToggleDrawer {
    type: ActionType.ToggleDrawer
}

export interface ToggleMobileMenu {
    type: ActionType.ToggleMobileMenu,
    toggle: boolean
}

export interface ClosePage {
    type: ActionType.ClosePage,
}

export interface SetSettingType {
    type: ActionType.SetSettingType,
    settingType: SettingType
}

export interface SetOptionsActive {
    type: ActionType.SetOptionsActive,
    optionsActive: boolean
}

export interface SetDeveloperOptions {
    type: ActionType.SetDeveloperOptions,
    developerOptions: boolean,
}

export interface SetLoading {
    type: ActionType.SetLoading,
    isLoading: boolean
}

export type NavigationActions = ClickMenu | ToggleDrawer | ToggleMobileMenu | ClosePage | SetSettingType | SetOptionsActive | SetDeveloperOptions | SetLoading;