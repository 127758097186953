import { Grid, Theme, useMediaQuery } from "@mui/material";
import FaceOverview from "./components/FaceOverview";
import { useContext, useEffect, useState } from "react";
import { BiometricFaceData, BiometricVoiceData, Operation } from "neurotec-megamatcherid-management-client";
import { BiometricDataAPI, OperationsAPI } from "../../config/management-api";
import VoiceOverview from "./components/VoiceOverview";
import OverviewGridContainer from "./components/OverviewGridContainer";
import { NavigationContext } from "../../components/Navigation/store/context";
import { setLoading } from "../../components/Navigation/store/reducer";

interface IOverviewProps {
    faceOperationUUID: string,
    voiceOperationUUID: string,
}

const Overview: React.FC<IOverviewProps> = (props) => {

    const [faceInfo, setFaceInfo] = useState<Operation>()
    const [voiceInfo, setVoiceInfo] = useState<Operation>()
    const [imageInfo, setImageInfo] = useState<BiometricFaceData> ()
    const [audioInfo, setAudioInfo] = useState<BiometricVoiceData>()
    const [tokenImage, setTokenImage] = useState<boolean> (false)
    const dispatch = useContext(NavigationContext).dispatch
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    useEffect(() => {
        const fetchImage = async () => {
            BiometricDataAPI.getBiometricFaceDataByUUID({ uuid: props.faceOperationUUID }).then(res => {
                setTokenImage(res.data.tokenImage? true : false)
                setImageInfo(res.data)
                dispatch(setLoading(false))
            }).catch(e => {
                dispatch(setLoading(false))
            })
        }
        const fetchAudio = async () => {
            BiometricDataAPI.getBiometricVoiceDataByUUID({ uuid: props.voiceOperationUUID }).then(res => {
                setAudioInfo(res.data)
                dispatch(setLoading(false))
            }).catch(e => {
                dispatch(setLoading(false))
            })            
        }
        const fetchFace = async () => {
            OperationsAPI.getOperationByUUID({uuid: props.faceOperationUUID }).then(res => {
                setFaceInfo(res.data)
            }).catch(e => {
                dispatch(setLoading(false))
            })
        }
        const fetchVoice = async () => {
            OperationsAPI.getOperationByUUID({uuid: props.voiceOperationUUID }).then(res => {
                setVoiceInfo(res.data)
            }).catch(e => {
                dispatch(setLoading(false))
            })
        }

        if (props.faceOperationUUID && props.faceOperationUUID.length !== 0) {
            dispatch(setLoading(true))
            fetchImage()
            fetchFace();
        }
        if (props.voiceOperationUUID && props.voiceOperationUUID.length !== 0) {
            dispatch(setLoading(true))
            fetchAudio()
            fetchVoice()
        }
    }, [props.faceOperationUUID, props.voiceOperationUUID, dispatch])

    const handleIsFaceOverviewBigger = (): boolean => {
        if (isMobile || props.voiceOperationUUID === undefined || props.voiceOperationUUID.length === 0)
            return true
        return false
    }

    
    const handleIsVoiceOverviewBigger = (): boolean => {
        if (isMobile || props.faceOperationUUID === undefined || props.faceOperationUUID.length === 0)
            return true
        return false
    }

    return (
        <OverviewGridContainer item md={10} xs={12} style={{paddingRight: "0px", paddingLeft: "0px"}}>
            <Grid container>
            {props.faceOperationUUID?
                <Grid item md={handleIsFaceOverviewBigger()? 12 : 6} xs={12}>
                    <FaceOverview
                        tokenImage={tokenImage}
                        operationInfo={faceInfo}
                        imageInfo={imageInfo}
                        bigger={handleIsFaceOverviewBigger()}
                    />
                </Grid>: null
            }
            {props.voiceOperationUUID?
                <Grid item md={handleIsVoiceOverviewBigger()? 12 : 6} xs={12}>
                    <VoiceOverview
                        audioInfo={audioInfo}
                        operationInfo={voiceInfo}
                        bigger={handleIsVoiceOverviewBigger()}
                    />
                </Grid>: null
            }
            </Grid>
        </OverviewGridContainer>
    );
}

export default Overview