import { Grid, styled, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from '@mui/material/styles';

const gradient = "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)"

const OpTextGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        width: "100vw",
        zIndex: "10",
        paddingTop: "15vh",
        paddingLeft: "5vw",
        paddingRight: "5vw",
        marginTop: useTheme().spacing(2),
        paddingBottom: "1rem",
        position: "fixed",
        bottom: "0px",
        left: "0px",
        background: gradient
    }
}));

interface IOperationTextGrid {
    item?: boolean,
    md: number,
    xs: number,
    children: any,
    glow: boolean
}

const OperationTextGrid: React.FC<IOperationTextGrid> = (props) => {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <OpTextGrid item={props.item} md={props.md} xs={props.xs} style={{background: props.glow && isMobile? gradient : "none"}}>
            {props.children}
        </OpTextGrid>
    );
} 

export default OperationTextGrid