import { THEME } from "../../../config";
import { styled } from "@mui/material/styles";
import { Slider as MuiSlider } from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const greenColor = THEME.palette.primary.main
const midGradientColor = "#bdc447"
const redColor = THEME.palette.error.main

const SliderFalling = styled(MuiSlider) ({
    '& .Mui-disabled': {
        color: THEME.palette.primary.main,
        opacity: "60%"
    },
    '&.Mui-disabled .MuiSlider-rail': {
        opacity: "50%"
    },
    '&.Mui-disabled .MuiSlider-markLabel': {
        opacity: "50%"
    },
    '& .MuiSlider-markLabel': {
        color: THEME.palette.text.primary
    },
    '.MuiSlider-rail': {
        backgroundImage: "linear-gradient(90deg, " + THEME.palette.error.main + "," + midGradientColor + "," + THEME.palette.primary.main + ")",
        opacity: "100%"
    },
    '.MuiSlider-track': {
        color: "white",
        opacity: "0%"
    },
    '.MuiSlider-mark': {
        opacity: "0%"
    },
    '.MuiSlider-thumb': {
        color: "red"
    }
});

const SliderRaising = styled(MuiSlider) ({
    '& .Mui-disabled': {
        color: THEME.palette.primary.main,
        opacity: "60%"
    },
    '&.Mui-disabled .MuiSlider-rail': {
        opacity: "50%"
    },
    '&.Mui-disabled .MuiSlider-markLabel': {
        opacity: "50%"
    },
    '& .MuiSlider-markLabel': {
        color: THEME.palette.text.primary,
        fontSize: "0.75rem"
    },
    '.MuiSlider-rail': {
        backgroundImage: "linear-gradient(90deg, " + THEME.palette.primary.main + "," + midGradientColor + "," + THEME.palette.error.main + ")",
        opacity: "100%"
    },
    '.MuiSlider-track': {
        color: "white",
        opacity: "0%"
    },
    '.MuiSlider-mark': {
        opacity: "0%"
    }
});


const SliderInfinite = styled(MuiSlider) ({
    '& .Mui-disabled': {
        color: THEME.palette.primary.main,
        opacity: "60%"
    },
    '&.Mui-disabled .MuiSlider-rail': {
        opacity: "50%"
    },
    '&.Mui-disabled .MuiSlider-markLabel': {
        opacity: "50%"
    },
    '& .MuiSlider-markLabel': {
        color: THEME.palette.text.primary,
        fontSize: "0.75rem"
    },
    '.MuiSlider-rail': {
        backgroundImage: "linear-gradient(90deg, " + THEME.palette.primary.main + "," + midGradientColor + "," + THEME.palette.error.main + ")",
        opacity: "100%"
    },
    '.MuiSlider-track': {
        color: "white",
        opacity: "0%"
    },
    '.MuiSlider-mark': {
        opacity: "0%"
    }
});

const SliderStatic = styled(MuiSlider) ({
    '& .Mui-disabled': {
        color: THEME.palette.primary.main,
        opacity: "60%"
    },
    '&.Mui-disabled .MuiSlider-rail': {
        opacity: "50%"
    },
    '&.Mui-disabled .MuiSlider-markLabel': {
        opacity: "50%"
    },
    '& .MuiSlider-markLabel': {
        color: THEME.palette.text.primary,
        fontSize: "0.75rem"
    },
    '.MuiSlider-rail': {
        color: THEME.palette.primary.main,
        opacity: "100%"
    },
    '.MuiSlider-track': {
        color: "white",
        opacity: "0%"
    },
    '.MuiSlider-mark': {
        opacity: "0%"
    }
});

const SliderFallingMono = styled(MuiSlider) ({
    '& .Mui-disabled': {
        color: THEME.palette.primary.main,
        opacity: "60%"
    },
    '&.Mui-disabled .MuiSlider-rail': {
        opacity: "50%"
    },
    '&.Mui-disabled .MuiSlider-markLabel': {
        opacity: "50%"
    },
    '& .MuiSlider-markLabel': {
        color: THEME.palette.text.primary,
        fontSize: "0.75rem"
    },
    '.MuiSlider-rail': {
        backgroundImage: "linear-gradient(90deg, #ffffff, #808080, #000000)",
        opacity: "100%"
    },
    '.MuiSlider-track': {
        color: "white",
        opacity: "0%"
    },
    '.MuiSlider-mark': {
        opacity: "0%"
    },
    "& .MuiSlider-thumb": {
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.65), 0px 2px 2px 0px rgba(0,0,0,0.50), 0px 1px 5px 0px rgba(0,0,0,0.35);" 
    }
});

const SliderRaisingMono = styled(MuiSlider) ({
    '& .Mui-disabled': {
        color: THEME.palette.primary.main,
        opacity: "60%"
    },
    '&.Mui-disabled .MuiSlider-rail': {
        opacity: "50%"
    },
    '&.Mui-disabled .MuiSlider-markLabel': {
        opacity: "50%"
    },
    '& .MuiSlider-markLabel': {
        color: THEME.palette.text.primary,
        fontSize: "0.75rem"
    },
    '.MuiSlider-rail': {
        backgroundImage: "linear-gradient(90deg, #000000, #808080, #ffffff)",
        opacity: "100%"
    },
    '.MuiSlider-track': {
        color: "white",
        opacity: "0%"
    },
    '.MuiSlider-mark': {
        opacity: "0%"
    },
    "& .MuiSlider-thumb": {
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.65), 0px 2px 2px 0px rgba(0,0,0,0.50), 0px 1px 5px 0px rgba(0,0,0,0.35);" 
    }
});

interface ISlider {
    active: boolean,
    minValue: number,
    maxValue: number,
    steps?: number,
    value: number,
    valueLabel?: (value: number) => string
    saveValue: (value: number) => void,
    commitValue: (value: number) => void,
    type: "raising" | "falling" | "static" | "infinite" | "raisingMono" | "fallingMono",
}

const Slider: React.FC<ISlider> = (props) => {

    const [value, setValue] = useState(props.value)
    const [thumbColor, setThumbColor] = useState<string>("green")
    const qrId = useRef<NodeJS.Timeout>();

    const setStartColor = useCallback(() => {
        if (props.type === "fallingMono" || props.type === "raisingMono")
            return "#000000"
        return greenColor
    }, [props.type])

    const setMidColor = useCallback(() => {
        if (props.type === "fallingMono" || props.type === "raisingMono")
            return "#808080"
        return midGradientColor
    }, [props.type])

    const setEndColor = useCallback(() => {
        if (props.type === "fallingMono" || props.type === "raisingMono")
            return "#ffffff"
        return redColor
    }, [props.type])

    const getSliderThumbColor = useCallback((val: number, falling: boolean, startColor: string, midColor: string, endColor: String) => {
        let c1: string;
        let c2: string;
        let p = (val - props.minValue) / Math.abs(props.maxValue - props.minValue)
        if (p < 0.5) {
            if (falling) {
                c1 = endColor.substring(1)
                c2 = midColor.substring(1)
            } else {
                c1 = startColor.substring(1)
                c2 = midColor.substring(1)
            }
            p = p * 2
        } else {
            if (falling) {
                c1 = midColor.substring(1)
                c2 = startColor.substring(1)
            } else {
                c1 = midColor.substring(1)
                c2 = endColor.substring(1)
            }
            p = p * 2 - 1
        }
    
        let difRed = parseInt(c2.substring(0, 2), 16) - parseInt(c1.substring(0, 2), 16)
        let difGreen = parseInt(c2.substring(2, 4), 16) - parseInt(c1.substring(2, 4), 16)
        let difBlue = parseInt(c2.substring(4, 6), 16) - parseInt(c1.substring(4, 6), 16)
    
        let red = Math.round(parseInt(c1.substring(0, 2), 16) + (difRed * p))
        let green = Math.round(parseInt(c1.substring(2, 4), 16) + (difGreen * p))
        let blue = Math.round(parseInt(c1.substring(4, 6), 16) + (difBlue * p))
    
        setThumbColor("rgb(" + red + " " + green + " " + blue + ")")
    }, [props.maxValue, props.minValue])

    useEffect(() => {
        setValue(props.value)
        if (props.type !== "static")
            getSliderThumbColor(props.value as number, (props.type === "falling" || props.type === "fallingMono"), setStartColor(), setMidColor(), setEndColor())
    }, [props.value, props.maxValue, props.minValue, props.type, getSliderThumbColor, setStartColor, setMidColor, setEndColor])

    const marks = [
        {
            value: props.minValue,
            label: props.minValue
        },
        {
            value: props.maxValue,
            label: props.maxValue
        }
    ]

    const handleBlur = () => {
        if (value < props.minValue) {
            setValue(props.minValue);
        } else if (value > props.maxValue) {
            setValue(props.maxValue);
        }
    };

    const handleSliderChange = (event: Event, newValue: number | number[], activeThumb: number) => {
        setValue(newValue as number)
        props.saveValue(newValue as number)
    }

    const handleSliderAndThumbChange = (newValue: number | number[], falling: boolean) => {
        if (value !== newValue) {
            setValue(newValue as number)
            if (qrId.current !== undefined)
                clearTimeout(qrId.current)
            qrId.current = setTimeout(() => {
                getSliderThumbColor(newValue as number, (props.type === "falling" || props.type === "fallingMono"), setStartColor(), setMidColor(), setEndColor())
                props.saveValue(newValue as number)
            }, 50);
        }
    }

    const handleSliderCommittedChange = (event: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
        setValue(value as number)
        if (qrId.current !== undefined)
            clearInterval(qrId.current)
        qrId.current = undefined
        props.commitValue(value as number)
    }

    const selectSlider = () => {
        switch(props.type) {
            case "falling":
                return (
                    <SliderFalling
                        sx={{'& .MuiSlider-thumb': {color: thumbColor}}}
                        disabled={!props.active}
                        value={value}
                        onChange={(e, v, a) => handleSliderAndThumbChange(v, true)}
                        onChangeCommitted={handleSliderCommittedChange}
                        onBlur={handleBlur}
                        max={props.maxValue}
                        min={props.minValue}
                        step={props.steps? props.steps : 1}
                        marks={props.steps? true : marks}
                        color="primary"
                        valueLabelFormat={props.valueLabel}
                        valueLabelDisplay={props.valueLabel !== undefined ? "auto" : "off"}
                    />
                );
            case "raising":
                return (
                    <SliderRaising
                        sx={{'& .MuiSlider-thumb': {color: thumbColor}}}
                        disabled={!props.active}
                        value={value}
                        onChange={(e, v, a) => handleSliderAndThumbChange(v, false)}
                        onChangeCommitted={handleSliderCommittedChange}
                        onBlur={handleBlur}
                        max={props.maxValue}
                        min={props.minValue}
                        step={props.steps? props.steps : 1}
                        marks={props.steps? true : marks}
                        color="primary"
                        valueLabelFormat={props.valueLabel}
                        valueLabelDisplay={props.valueLabel !== undefined ? "auto" : "off"}
                    />
                );
            case "static":
                return (
                    <SliderStatic
                        disabled={!props.active}
                        aria-label="Restricted values"
                        value={value}
                        onChange={handleSliderChange}
                        onChangeCommitted={handleSliderCommittedChange}
                        onBlur={handleBlur}
                        max={props.maxValue}
                        min={props.minValue}
                        step={props.steps? props.steps : 1}
                        marks={props.steps? true : marks}
                        color="primary"
                        valueLabelFormat={props.valueLabel}
                        valueLabelDisplay={props.valueLabel !== undefined ? "auto" : "off"}
                    />
                );
            case "infinite":
                return (
                    <SliderInfinite
                        sx={{'& .MuiSlider-thumb': {color: thumbColor}}}
                        disabled={!props.active}
                        value={value}
                        onChange={(e, v, a) => handleSliderAndThumbChange(v, false)}
                        onChangeCommitted={handleSliderCommittedChange}
                        onBlur={handleBlur}
                        max={props.maxValue}
                        min={props.minValue}
                        step={1}
                        marks={marks}
                        color="primary"
                        valueLabelFormat={props.valueLabel}
                        valueLabelDisplay={props.valueLabel !== undefined ? "auto" : "off"}
                    />
                )
            case "raisingMono":
                return (
                    <SliderRaisingMono
                        sx={{'& .MuiSlider-thumb': {color: thumbColor}}}
                        disabled={!props.active}
                        value={value}
                        onChange={(e, v, a) => handleSliderAndThumbChange(v, false)}
                        onChangeCommitted={handleSliderCommittedChange}
                        onBlur={handleBlur}
                        max={props.maxValue}
                        min={props.minValue}
                        step={1}
                        marks={marks}
                        color="primary"
                        valueLabelFormat={props.valueLabel}
                        valueLabelDisplay={props.valueLabel !== undefined ? "auto" : "off"}
                    />
                )
            case "fallingMono":
                return (
                    <SliderFallingMono
                        sx={{'& .MuiSlider-thumb': {color: thumbColor}}}
                        disabled={!props.active}
                        value={value}
                        onChange={(e, v, a) => handleSliderAndThumbChange(v, false)}
                        onChangeCommitted={handleSliderCommittedChange}
                        onBlur={handleBlur}
                        max={props.maxValue}
                        min={props.minValue}
                        step={1}
                        marks={marks}
                        color="primary"
                        valueLabelFormat={props.valueLabel}
                        valueLabelDisplay={props.valueLabel !== undefined ? "auto" : "off"}
                    />
                )
        }
    }
    
    return (
        selectSlider()
    )
}

export default memo(Slider)