import { Button, Paper, Skeleton, styled, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import TextCell from "../components/TextCell";
import { useContext, useEffect, useState } from "react";
import { getManagementInfo } from "../../../helpers/startOperation";
import { showMessage } from "../../../store/reducer";
import { AppContext } from "../../../store/context";
import { IManagementInfo } from "../../../types/FaceVerification";
import { THEME } from "../../../config";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { SubjectsApiClearDbRequest } from "neurotec-megamatcherid-management-client";
import { SubjectsAPI } from "../../../config/management-api";
import { SettingTable, settingUseStyles } from "../Settings";
import { useSnackbar } from "notistack";
import DownloadSettingsTable from "../components/DownloadSettingsTable";
import { setLoading } from "../../../components/Navigation/store/reducer";
import { NavigationContext } from "../../../components/Navigation/store/context";

const ClearButton = styled(Button)(() => ({
    fontWeight: 500,
    fontSize: "1.1rem",
    textTransform: "none",
    color: THEME.palette.text.secondary
}))

interface IManagement {
    hasClear?: boolean
}

const Management: React.FC<IManagement> = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const {state, dispatch} = useContext(AppContext)
    const navigationDispatch = useContext(NavigationContext).dispatch
    const [managementState, setManagementState] = useState<IManagementInfo | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false)
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const classes = settingUseStyles()

    useEffect(() => {
        const fetchData = async () => {
            try {
                navigationDispatch(setLoading(true))
                const managementInfo = await getManagementInfo();
                navigationDispatch(setLoading(false))
                if (!managementInfo) {
                    dispatch(showMessage({ message: "Could not get management info", type: "error" }))
                    return;
                }
                setManagementState(managementInfo)
            } catch (error: any) {
                navigationDispatch(setLoading(false))
                dispatch(showMessage({ message: error.message, type: "error" }))
            }
        }
        fetchData()
    }, [dispatch, navigationDispatch])

    const handleButtonClick = () => {
        setDialogOpen(true)
    }

    const clearDb = () => {
        if (managementState?.prefixMode === false) {
            enqueueSnackbar("Clear DB can only be done from management page", {variant: "warning"})
            return;
        }
        const request: SubjectsApiClearDbRequest = {
            prefix: (managementState?.prefixMode && state.prefix !== null) ? state.prefix : undefined
        }
        SubjectsAPI.clearDb(request).then(res => {
            enqueueSnackbar("DB cleared successfully", {variant: "info"})
        }).catch(err => {
            console.error(err);
            enqueueSnackbar("Unable to clear DB", {variant: "error"})
        })
    }

    const showButton = () => {
        return (
            (managementState?.allowClearDb && managementState?.prefixMode)?
                <ClearButton
                    onClick={handleButtonClick}
                    disabled={!managementState?.allowClearDb}
                    variant="contained"
                    color="error"
                >
                    Clear database
                </ClearButton>
                : null
        )
    }

    const getColSpan = (): number => {
        if (isMobile)
            return 3;
        if (isSmall)
            return 2;
        return 1
    }

    const showTableRow = (name: string, value: boolean | undefined) => {
        if (isMobile) {
            return (
                <TableRow>
                    <TableCell width={"50%"} colSpan={getColSpan()}>
                        {name}
                    </TableCell>
                    <TableCell colSpan={3}>
                        {value === undefined ? <Skeleton/> : value.toString()}
                    </TableCell>
                </TableRow>
            );
        } if (isSmall) {
            return (
                <TableRow>
                    <TableCell width={"50%"} colSpan={getColSpan()}>
                        {name}
                    </TableCell>
                    <TableCell colSpan={3}>
                        {value === undefined ? <Skeleton/> : value.toString()}
                    </TableCell>
                </TableRow>
            );
        } else {
            return (
                <TableRow>
                    <TableCell width={"25%"} colSpan={getColSpan()}>
                        {name}
                    </TableCell>
                    <TableCell colSpan={3}>
                        {value === undefined ? <Skeleton/> : value.toString()}
                    </TableCell>
                </TableRow>
            );
        }
    }

    return (
        <>
            <TableContainer component={Paper} className={classes.container}>
                <SettingTable>
                    <TableHead>
                        <TableRow>
                            <TextCell head colspan={props.hasClear? 3 : 6} title="Management settings" subtitle="You may find the necessary management information here. Please get in touch with us if you can't find what you're looking for."/>
                            {props.hasClear?  
                                <TableCell align="right" colSpan={3} width={"20%"}>
                                    {showButton()}
                                </TableCell>
                                : null
                            }
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={getColSpan()}>
                                Name
                            </TableCell>
                            <TableCell colSpan={3}>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showTableRow("Allow setting override", managementState?.allowSettingsOverride)}
                        {showTableRow("Allow clear db", managementState?.allowClearDb)}
                        {showTableRow("Prefix mode enabled", managementState?.prefixMode)}
                        {showTableRow("Prefix", managementState?.prefixMode)}
                    </TableBody>
                </SettingTable>
            </TableContainer>
            <DownloadSettingsTable
                isMobile={isMobile}
            />
            <ConfirmationDialog
                open={dialogOpen}
                setOpen={setDialogOpen}
                title="Clear database"
                cancelText="Cancel"
                confirmText="Yes, clear database"
                confirm={clearDb}
                confirmColor="error"
                text="This action will clear all data associated with your unique session prefix"
            />
        </>
    );
}

export default Management