import { Box, Button, Grid, styled } from "@mui/material";
import { BiometricVoiceData } from "neurotec-megamatcherid-management-client";
import { useEffect, useRef, useState } from "react";
import WaveSurfer from 'wavesurfer.js'
import { THEME } from "../../config";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const ContainerGrid = styled(Grid)(({ theme }) => ({
    marginTop: "1rem",
    justifyContent: "flex-start",
    alignItems: "center"
}))

interface IAudioOperationSummary {
    audioInfo: BiometricVoiceData | undefined,
    bigger: boolean
}

const AudioOperationSummary: React.FC<IAudioOperationSummary> = (props) => {

    const waveformRef = useRef();
    const [isPlaying, setIsPlaying] = useState(false)
    const [waveSurfer, setWaveSurfer] = useState<WaveSurfer | undefined>(undefined)

    const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
      
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
      
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
      
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
          
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const handleButtonClick = () => {
        setIsPlaying(!isPlaying)
        if (!isPlaying) {
            waveSurfer?.play()
        } else {
            waveSurfer?.pause()
        }
    }

    useEffect(() => {
        if (waveSurfer === undefined && waveformRef.current && props.audioInfo && props.audioInfo.audio) {
            const url = URL.createObjectURL(b64toBlob(props.audioInfo.audio, "audio/mpeg"))
            const ws = WaveSurfer.create({
                container: waveformRef.current,
                waveColor: '#ffffff',
                progressColor: THEME.palette.primary.main,
                url: url,
                barWidth: 5,
                barRadius: 3,
                barGap: 1,
                height: "auto",
                normalize: true,
                backend: "WebAudio",
                renderFunction: (channels, ctx) => {
                    const {width, height} = ctx.canvas;
                    const channelData = channels[0]; // Assuming mono for simplicity
                    const scale = Math.floor(channelData.length / width);
                    const barWidth = 5;
                    const gap = 1;

                    const drawBars = (channelData: any, ctx: any, barWidth: any, gap: any) => {
                        // Calculate height of every bar
                        let valueList = []
                        for (let i = 0; i < width; i += barWidth + gap) {
                            let normalizedValue = 0;
                            for (let j = i * scale; j < (i * scale) + scale; j++) {
                                normalizedValue += Math.sqrt(Math.abs(channelData[j]))
                            }
                            valueList.push(Math.max(normalizedValue / scale, 0.005))
                        }

                        // Scale according to loudness
                        let averageValue = valueList.reduce((a, c) => a + c) / valueList.length
                        let maxHeight = 1;
                        if (averageValue < 0.5) {
                            maxHeight += Math.abs(averageValue - 0.5) * 6 // Change 6 to adjust scalling of loudness 
                        }

                        // Draw bars
                        for (let i = 0, j = 0; i < width; i += barWidth + gap, j++) {
                            const barHeight = valueList[j] * (height * maxHeight)
                            ctx.fillRect(i, (height - barHeight) / 2, barWidth, barHeight);
                        }
                    };
    
                    ctx.clearRect(0, 0, width, height);
                    ctx.fillStyle = 'white';
                    drawBars(channelData, ctx, barWidth, gap);
                },
            });
            ws.on("finish", () => {setIsPlaying(false)})
            setWaveSurfer(ws)
        }

    }, [props, waveSurfer, waveformRef]);

    useEffect(() => {
        return () => {
            if (waveSurfer) {
                waveSurfer.pause()
                waveSurfer.destroy()
                setWaveSurfer(undefined)
            }
        };
    }, [waveSurfer])

    return (
        <ContainerGrid container>
            <Box style={{alignSelf: "center", alignItems: "center", width: "4rem"}}>   
                <Button
                    disableRipple
                    sx={{"&.MuiButtonBase-root:hover": {
                        backgroundColor: "transparent"}

                    }}
                    variant={"text"}
                    onClick={handleButtonClick}
                    style={{fontSize: "2rem"}}
                >
                    {isPlaying? <PauseIcon/> : <PlayArrowIcon/>}
                </Button>

            </Box>
            <Box style={{width: "calc(100% - 4rem)"}}>
                <Box
                    style={{height: props.bigger? "30vh" : "15vh", marginRight: "1rem"}}
                    ref={waveformRef}
                />
            </Box>
        </ContainerGrid>
    )
}

export default AudioOperationSummary;