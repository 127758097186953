import { Container, Grid, Theme, useMediaQuery, styled, TableRow, Button, TableCell, Table } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SettingsSidebar from "./SettingsSidebar";
import Verification from "./tabs/Verification";
import Capturing from "./tabs/Capturing";
import About from "./tabs/About";
import { FVAdvancedInitParams, FVInitParams } from "neurotec-megamatcherid-management-client";
import ICAO from "./tabs/ICAO";
import Liveness from "./tabs/Liveness";
import Management from "./tabs/Management";
import Reset from "./tabs/Reset";
import { ISliderInterface } from "./components/SliderCell";
import { initialFvParameters, useLocalStorage } from "../../helpers/useLocalStorage";
import { mmidInitParamsStorage } from "../../config/const";
import { AppContext } from "../../store/context";
import { LivenessMode } from "../../types/FaceVerification";
import { useSnackbar } from "notistack";
import { ActionType } from "../../components/Navigation/store/actions";
import { NavigationContext } from "../../components/Navigation/store/context";
import { makeStyles } from "@mui/styles";
import Developer from "./tabs/Developer";

const GridBar = styled(Grid)(() => ({
    paddingTop: "2.1rem",
    position: "sticky",
    top: "max(7vh, 50px)",
    height: "fit-content"
}));

const GridTab = styled(Grid)(({ theme }) => ({
    paddingTop: "2rem",
    paddingLeft: "2rem",
    [theme.breakpoints.down("md")]: {
        paddingLeft: 0
    }
}));

export const SettingContainer = styled(Container)(({ theme }) => ({
    maxWidth: "97vw",
    [theme.breakpoints.down("md")]: {
        maxWidth: "100%"
    }
}));

export const SettingTable = styled(Table)(() => ({
    tableLayout: "fixed"
}))

export const settingUseStyles = makeStyles(() => ({
    container: {
        marginBottom: "2rem",
        tableLayout: "fixed"
    }
}))

export const SmallTextTableRow = styled(TableRow)(() => ({
    borderBottom: "none"
}))

export const SmallContentTableRow = styled(TableRow)(() => ({
    paddingLeft: "16px"
}))

export const NormalTableRow = styled(TableRow)(() => ({
    paddingLeft: "0px"
}))

export const DefaultButton = styled(Button)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
        paddingRight: "0px"
    }
}))

export const TableCellButton = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "0px",
    }
}))

export enum SettingType {
    CAPTURING = "CAPTURING",
    VERIFICATION = "VERIFICATION",
    LIVENESS = "LIVENESS",
    ICAO = "ICAO",
    MANAGEMENT = "MANAGEMENT",
    DEVELOPER = "DEVELOPER",
    RESET = "RESET",
    ABOUT = "ABOUT",
}

const Settings: React.FC = () => {

    const { enqueueSnackbar } = useSnackbar();
    const { state, dispatch } = useContext(NavigationContext)
    const [setting, setSetting] = useState<SettingType>(state.settingType)
    const [options, setOptions] = useLocalStorage<FVInitParams>(mmidInitParamsStorage, initialFvParameters);
    const [qualityImageSize, setQualityImageSize] = useState<[number, number]>([1280, 720])
    const appState = useContext(AppContext).state
    const breakpoint = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        setSetting(state.settingType)
    }, [enqueueSnackbar, state])

    const setSettingType = (type: SettingType) => {
        setSetting(type)
        dispatch({type: ActionType.SetSettingType, settingType: type})
    }

    const findDefaultOption = (option: string): FVAdvancedInitParams | undefined => {
        return appState.defaultOptions.find((el) => el.key === option)
    }

    const findOption = (option: string, advanced?: boolean) : FVAdvancedInitParams | undefined => {
        return options.advancedParameters?.find((el) => el.key === option)
    }

    const convertAdvancedToSliderInterface = (param: FVAdvancedInitParams) => {
        let key: string | undefined = undefined
        let value: number | undefined = undefined
        
        if (param.key)
            key = param.key
        if (param.value || param.value as unknown as number === 0)
            value = Number(param.value)
        if (key === undefined || value === undefined) {
            enqueueSnackbar("Could not convert advanced option", {variant: "error"})
            return undefined
        }
            
        return {key: key, value: value}
    }

    const findFVSliderInterface = (param: string): ISliderInterface | undefined => {
        if (!options.advancedParameters)
            return undefined
        let par = findOption(param)
        if (par) {
            return convertAdvancedToSliderInterface(par)
        } else {
            if (!appState.defaultOptions || appState.defaultOptions.length === 0)
                return undefined
            par = findDefaultOption(param)
            if (par) {
                return convertAdvancedToSliderInterface(par)
            } else {
                enqueueSnackbar("Could not get default properties", {variant: "error"})
            }
            return undefined
        }
    }

    const findDefaultFVSliderInterface = (param: string): ISliderInterface | undefined => {
        let par = findDefaultOption(param)
        if (par) {
            return convertAdvancedToSliderInterface(par)
        } else {
            return undefined
        }
    }

    const setOption = (op: ISliderInterface, basic?: boolean) => {
        if (basic)
            setNewBasicOption(op)
        else
            setNewOptions(op)
    }

    const setNewOptions = (op: ISliderInterface) => {
        let par = findOption(op.key)
        if (par) {
            par.value = op.value.toString();
            setOptions({...options, advancedParameters: options.advancedParameters})
        } else {
            setNewAdvancedOption(op.key, op.value.toString())
        }
    }

    const setNewAdvancedOption = (key: string, value: string) => {
        if (options.advancedParameters)
            setOptions({...options, advancedParameters: [...options.advancedParameters, {key: key, value: value}]})
    }

    const setLivenessMode = (liveness: LivenessMode) => {
        setOptions({...options, livenessMode: liveness as number})
    }

    const removeAdvancedSetting = (key: string) => {
        setOptions({...options, advancedParameters: options.advancedParameters?.filter((par) => {
            return par.key !== key
        })})
    }

    const resetValueToDefault = (name: string, basic?: boolean) => {
        if (basic)
            resetBasicValueToDefault(name)
        else
            resetAdvanceValueToDefault(name)
    }

    const resetBasicValueToDefault = (name: string) => {
        setNewBasicOption({key: name, value: 0})
    }

    const setNewBasicOption = (op: ISliderInterface) => {
        switch (op.key) {
            case "timeout":
                setOptions({...options, timeout: op.value})
        }
    } 

    const resetAdvanceValueToDefault = (name: string) => {
        let defaultPar = findDefaultOption(name)
        if (defaultPar) {
            if (defaultPar.key && defaultPar.value) {
                let par = findOption(name)
                if (par) {
                    par.value = defaultPar.value
                    setOptions({...options, advancedParameters: options.advancedParameters})
                }
            }
            if (name === "IcaoWarningsFilter") {
                let par = findOption(name)
                if (par) {
                    if (defaultPar.value){
                        par.value = defaultPar.value
                    } else {
                        par.value = ""
                    }
                    setOptions({...options, advancedParameters: options.advancedParameters})
                }
            }
        } else {
            enqueueSnackbar("Could not get default properties", {variant: "error"})
        }
    }

    const resetAllParametersToDefault = () => {
        setOptions(initialFvParameters)
    }

    const handleQualityImageSetting = (w: number, h: number) => {
        setQualityImageSize([w, h])
        let par = options.advancedParameters?.find((e) => e.key === "StrictQualityImageSize")
        if (par) {
            const nextOptions = options.advancedParameters?.map((p) => {
                if (p.key === "StrictQualityImageSize")
                    return {key: "StrictQualityImageSize", value: (w * h).toString()}
                else {
                    return p
                }
            })
            setOptions({...options, advancedParameters: nextOptions})
        } else {
            if (options.advancedParameters)
                setOptions({...options, advancedParameters: [...options.advancedParameters, {key: "StrictQualityImageSize", value: (w * h).toString()}]})
        }
    }

    const getStrictQualityImageSize = () => {
        let par = findOption("StrictQualityImageSize")?.value
        if (par !== undefined) {
            return [qualityImageSize[0], qualityImageSize[1], Number(par)] as [number, number, number]
        }
        return [...qualityImageSize, qualityImageSize[0] * qualityImageSize[1]] as [number, number, number]
    }

    const getTab = (type: SettingType) => {
        switch(type) {
            case SettingType.CAPTURING:
                return <Capturing
                            options={options}
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setNewOption={setOption} 
                            timeout={options.timeout}
                            setOptions={setOptions}
                            resetValueToDefault={resetValueToDefault}
                            handleQualityImageSetting={handleQualityImageSetting}
                            qualityImageSize={getStrictQualityImageSize()}
                        />
            case SettingType.VERIFICATION:
                return <Verification 
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface} 
                            findFVSliderInterface={findFVSliderInterface} 
                            setNewOption={setOption} 
                            resetValueToDefault={resetValueToDefault}
                        />
            case SettingType.LIVENESS:
                return <Liveness 
                            options={options} 
                            setLivenessMode={setLivenessMode} 
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setNewOption={setOption}
                            setOptions={setOptions}
                            resetValueToDefault={resetValueToDefault}
                            handleQualityImageSetting={handleQualityImageSetting}
                        />
            case SettingType.ICAO:
                return <ICAO 
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setNewOption={setOption}
                            resetValueToDefault={resetValueToDefault}
                            options={options}
                            setOptions={setOptions}
                        />
            case SettingType.MANAGEMENT:
                return <Management hasClear />
            case SettingType.DEVELOPER:
                return <Developer
                            setNewOption={setNewAdvancedOption}
                            options={options.advancedParameters ?? []}
                            removeOption={removeAdvancedSetting}    
                        />
            case SettingType.RESET:
                return <Reset reset={resetAllParametersToDefault}/>
            case SettingType.ABOUT:
                return <About />
            default:
                return <Capturing
                            options={options}
                            findDefaultFVSliderInterface={findDefaultFVSliderInterface}
                            findFVSliderInterface={findFVSliderInterface}
                            setNewOption={setOption}
                            setOptions={setOptions}
                            timeout={options.timeout}
                            resetValueToDefault={resetValueToDefault}
                            handleQualityImageSetting={handleQualityImageSetting}
                            qualityImageSize={getStrictQualityImageSize()}
                        />
        }
    }

    return(
        <SettingContainer>
            <Grid container style={{position: "relative"}}>
                <GridBar item md={2.5} xs={0}>
                {breakpoint?
                    <SettingsSidebar activeSetting={setting} setSettings={setSettingType} />
                    :
                    null
                }
                </GridBar>
                <GridTab item md={9.5} xs={12}>
                    {getTab(setting)}
                </GridTab>
            </Grid>
        </SettingContainer>
    );
}

export default Settings