import { Autocomplete, TextField, Theme, Tooltip, useMediaQuery } from "@mui/material"
import { useCallback, useContext, useEffect, useState } from "react";
import { getManagementInfo } from "../../../helpers/startOperation";
import { SubjectId, SubjectsApiGetSubjectsIdListRequest } from "neurotec-megamatcherid-management-client";
import { SubjectsAPI } from "../../../config/management-api";
import { AppContext } from "../../../store/context";

interface ISubjectIdAutocomplete {
    error: string,
    selectedSubject: string,
    handleSubjectChange: (subject: string | null | undefined) => void,
    disabled?: boolean
}

const SubjectIdAutocomplete: React.FC<ISubjectIdAutocomplete> = (props) => {

    const [subjectIds, setSubjectIds] = useState<SubjectId[]>([])
    const isAutoFocus = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const state = useContext(AppContext).state

    const fetchData = useCallback(async (subjectId?: string) => {
        const managementInfo = await getManagementInfo();
        const request: SubjectsApiGetSubjectsIdListRequest = {
            prefix: (managementInfo?.prefixMode && state.prefix !== null) ? state.prefix : undefined,
            subjectId: subjectId ?? undefined
        }
        const subjectIds = (await SubjectsAPI.getSubjectsIdList(request)).data;
        setSubjectIds(subjectIds);
    }, [state.prefix])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <Autocomplete
            disabled={props.disabled}
            id="subject-select"
            freeSolo
            options={subjectIds.map(x => {
                return ((state.prefix) ? (x as string).replace(state.prefix + ".", "") : (x as string))
            })}
            ListboxProps={{ style: { maxHeight: '20rem' } }}
            value={props.selectedSubject}
            onChange={(event: any, newValue: string | null | undefined) => { props.handleSubjectChange(newValue) }}
            autoHighlight
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => (
                <li {...props}>
                    <Tooltip title={option} placement="bottom-start">
                        <span>{option}</span>
                    </Tooltip>
                </li>
            )}
            renderInput={(params) => 
                <Tooltip title={params.inputProps.value} enterDelay={1000} enterNextDelay={1000}>
                    <TextField
                        {...params}
                        error={props.error ? true : false}
                        helperText={props.error}
                        onChange={(event) => {props.handleSubjectChange(event.target.value); fetchData(event.target.value)}}
                        autoFocus={isAutoFocus}
                        required
                        margin="none"
                        label="Choose a Subject ID"
                        variant="outlined"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: "off",
                        }}
                    />
                </Tooltip>
            }
        />
    )
}

export default SubjectIdAutocomplete;