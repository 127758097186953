import { Typography, styled } from "@mui/material";

const OperationTypographyText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    fontWeight: 400,
    fontSize: "1rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}));

export default OperationTypographyText