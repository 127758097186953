import React from "react";
import { ActionType, ClickMenu, ClosePage, NavigationActions, SetDeveloperOptions, SetLoading, SetOptionsActive, SetSettingType } from "./actions";
import { NavigationState } from "./state";
import { SettingType } from "../../../containers/Settings/Settings";

export const navigationReducer = (state: NavigationState, action: NavigationActions): NavigationState => {
    switch (action.type) {
        case ActionType.ClickMenu:
            return { ...state, page: action.page, selected: true, isDrawerOpen: false };
        case ActionType.ToggleDrawer:
            return { ...state, isDrawerOpen: !state.isDrawerOpen };
        case ActionType.ToggleMobileMenu:
            return { ...state, isMobile: action.toggle };
        case ActionType.ClosePage:
            return { ...state, selected: false };
        case ActionType.SetSettingType:
            return { ...state, settingType: action.settingType };
        case ActionType.SetOptionsActive:
            return { ...state, optionsActive: action.optionsActive};
        case ActionType.SetDeveloperOptions:
            return { ...state, developerOptions: action.developerOptions }
        case ActionType.SetLoading:
            return { ...state, isLoading: action.isLoading};
        default:
            return state;
    }
}

export const selectMenu = (page: React.FC<any>): ClickMenu => {
    return {
        type: ActionType.ClickMenu,
        page: page
    }
};

export const closePage = (): ClosePage => {
    return {
        type: ActionType.ClosePage
    }
};

export const setSettingPage = (settingType: SettingType): SetSettingType => {
    return {
        type: ActionType.SetSettingType,
        settingType: settingType
    }
};

export const setOptionsActive = (optionsActive: boolean): SetOptionsActive => {
    return {
        type: ActionType.SetOptionsActive,
        optionsActive: optionsActive
    }
}

export const setDeveloperOptions = (developerOptions: boolean): SetDeveloperOptions => {
    return {
        type: ActionType.SetDeveloperOptions,
        developerOptions
    }
}

export const setLoading = (isLoading: boolean): SetLoading => {
    return {
        type: ActionType.SetLoading,
        isLoading: isLoading
    }
}
