import { Paper, TableBody, TableContainer, TableHead, TableRow, Theme, useMediaQuery } from "@mui/material";
import TextCell from "../components/TextCell";
import { ISliderInterface } from "../components/SliderCell";
import { DefaultButton, NormalTableRow, SettingTable, SmallContentTableRow, SmallTextTableRow, TableCellButton, settingUseStyles } from "../Settings";
import SliderRow from "../components/SliderRow";
import ConfirmationDialog from "../../../components/Misc/Dialog/ConfirmationDialog";
import { useState } from "react";
import { useSnackbar } from "notistack";
import DoubleInputCell from "../components/DoubleInputCell";
import { FVInitParams } from "neurotec-megamatcherid-management-client";
import SwitchCell from "../components/SwitchCell";

interface ICapturing {
    options: FVInitParams,
    findDefaultFVSliderInterface: (name: string) => ISliderInterface | undefined,
    findFVSliderInterface: (name: string) => (ISliderInterface | undefined),
    setNewOption: (op: ISliderInterface, basic?: boolean) => void,
    setOptions: React.Dispatch<React.SetStateAction<FVInitParams>>,
    resetValueToDefault: (name: string, basic?: boolean) => void,
    handleQualityImageSetting: (w: number, h: number) => void,
    qualityImageSize: [number, number, number],
    timeout: number | undefined
}

const Capturing: React.FC<ICapturing> = (props) => {

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const classes = settingUseStyles();
    const { enqueueSnackbar } = useSnackbar();

    const setDisallowMultipleFaces = (bool: boolean) => {
        let backgroundRemoval = props.options.advancedParameters?.find((e) => e.key === "DisallowMultipleFaces")
        if (backgroundRemoval) {
            backgroundRemoval.value = bool.toString();
            props.setOptions({...props.options, advancedParameters: props.options.advancedParameters})
        } else {
            if (props.options.advancedParameters)
                props.setOptions({...props.options, advancedParameters: [...props.options.advancedParameters, {key: "DisallowMultipleFaces", value: bool.toString()}]})
        }
    }

    const getDisallowMultipleFaces = () => {
        let backgroundRemoval = props.options.advancedParameters?.find((e) => e.key === "DisallowMultipleFaces")
        if (backgroundRemoval) {
            if (backgroundRemoval.value) {
                if (backgroundRemoval.value === "true")
                    return true
                else 
                    return false
            }
        }
        return true;
    }

    const handleResetClick = () => {
        enqueueSnackbar("Capturing settings reset", {variant: "info"})
        props.resetValueToDefault("QualityThreshold")
        props.resetValueToDefault("FramesToSkip")
        props.resetValueToDefault("timeout", true)
        props.resetValueToDefault("DisallowMultipleFaces")
        props.resetValueToDefault("StrictQualityTimeout")
        props.handleQualityImageSetting(1280, 720)
    }

    const doubleInputRow = () => {
        if (isMobile) {
            return (
                <>
                <SmallTextTableRow>
                    <TextCell colspan={3} title="Strict Quality Image size" subtitle="Minimal frame pixel count to start detection. E.g. 480x640 would be 307200." />
                </SmallTextTableRow>
                <SmallContentTableRow>
                    <DoubleInputCell
                        qualityImageSize={props.qualityImageSize}
                        options={props.options}
                        setOptions={props.setOptions}
                        title="Image size"
                        active
                        colSpan={3}
                        saveSetting={props.handleQualityImageSetting}
                    />
                </SmallContentTableRow>
                </>
            )
        } else {
            return (
                <NormalTableRow>
                    <TextCell title="Strict Quality Image size" subtitle="Minimal frame pixel count to start detection. E.g. 480x640 would be 307200." />
                    <DoubleInputCell
                        qualityImageSize={props.qualityImageSize}
                        options={props.options}
                        setOptions={props.setOptions}
                        title="Image size"
                        active
                        colSpan={3}
                        saveSetting={props.handleQualityImageSetting}
                    />
                </NormalTableRow>
            )
        }
    }

    return (
        <>
        <TableContainer component={Paper} className={classes.container}>
            <SettingTable>
                <TableHead>
                    <TableRow>
                        <TextCell head title="Capturing" subtitle=""/>
                        <TableCellButton align="right" colSpan={isMobile? 2 : 3}>
                            <DefaultButton variant="text" color="primary" onClick={() => setOpenDialog(true)}>
                                Reset to default
                            </DefaultButton>
                        </TableCellButton>
                    </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TextCell title="Disallow multiple faces" subtitle="Fails the operation if multiple faces are detected."/>
                        <SwitchCell colSpan={isMobile? 2 : 3} state={getDisallowMultipleFaces()} setState={setDisallowMultipleFaces}/>
                    </TableRow>
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Timeout"
                        subtitle="Determines operation timeout in milliseconds."
                        title="Time value"
                        option={{key: "timeout", value: props.timeout? props.timeout : 0}}
                        defaultOption={{key: "timeout", value: 0}}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={120000}
                        type="static"
                        active
                        lockMaxValue
                        steps={1000}
                        basic
                        badValueRange={[0, 1000]}
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Quality"
                        subtitle="Face quality threshold."
                        title="Threshold range"
                        option={props.findFVSliderInterface("QualityThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("QualityThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active
                        lockMaxValue
                        noWarningMsg="Everything will pass"
                        alwaysWarningMsg="Nothing will pass"
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Detection confidence"
                        subtitle="Determines if the detection of a face is confident enough."
                        title="Threshold range"
                        option={props.findFVSliderInterface("DetectionConfidenceThreshold")}
                        defaultOption={props.findDefaultFVSliderInterface("DetectionConfidenceThreshold")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={100}
                        type="raising"
                        active
                        lockMaxValue
                        dontShowWarningMsg
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Frames to skip"
                        subtitle="Skips first X frames before starting detection."
                        title="Frames"
                        option={props.findFVSliderInterface("FramesToSkip")}
                        defaultOption={props.findDefaultFVSliderInterface("FramesToSkip")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={1000}
                        type="static"
                        active
                        lockMaxValue
                    />
                    <SliderRow
                        mobile={isMobile}
                        textTitle="Strict Quality Timeout"
                        subtitle="Indicates time for the image to reach timeout."
                        title="Timeout in ms"
                        option={props.findFVSliderInterface("StrictQualityTimeout")}
                        defaultOption={props.findDefaultFVSliderInterface("StrictQualityTimeout")}
                        setOption={props.setNewOption}
                        minValue={0}
                        maxValue={60000}
                        type="static"
                        active
                        lockMaxValue
                        steps={1000}
                    />
                    {doubleInputRow()}
                </TableBody>
            </SettingTable>
        </TableContainer>
        <ConfirmationDialog
            open={openDialog}
            setOpen={setOpenDialog}
            title="Reset capturing settings"
            text="Reset capturing settings to default values?"
            cancelText="Cancel"
            confirmText="Yes"
            confirm={handleResetClick}
            confirmColor="error"
        />
        </>
    );
}

export default Capturing;